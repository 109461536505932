import React, { useContext, useState } from "react";
import Introduction from "../../Components/Intro/Introduction";
import ResumeContext from "../../Context/ResumeContext";
import Theme1 from "../../Theme/Theme1/Theme1";
import Theme2 from "../../Theme/Theme2/Theme2";
import Theme3 from "../../Theme/Theme3/Theme3";
// import Theme4 from "../../Theme/Theme4/Theme4";
import BuilderArea from "../BuilderArea";

const Home = () => {
  const { currentTheme, showComponent, themeData, componentRef } =
    useContext(ResumeContext);
  const [token, setToken] = useState("");

  const renderTheme = () => {
    switch (currentTheme) {
      case "Theme1":
        return <Theme1 componentRef={componentRef} themeData={themeData} />;
      case "Theme2":
        return <Theme2 componentRef={componentRef} themeData={themeData} />;
      case "Theme3":
        return <Theme3 componentRef={componentRef} themeData={themeData} />;
      // case "Theme4":
      //   return <Theme4 componentRef={componentRef} themeData={themeData} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {!showComponent ? (
        <Introduction setToken={setToken} />
      ) : (
        <BuilderArea theme={renderTheme()} token={token} />
      )}
    </div>
  );
};

export default Home;
