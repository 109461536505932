const ThemeTemplateData = [
  {
    id: "Theme1",
    imageSrc:
      "https://user-images.githubusercontent.com/87645745/210406666-ef3e2d63-28ab-4f8c-95d2-af3e6ea60bcf.png",
    imageAlt: "Theme1",
  },
  {
    id: "Theme2",
    imageSrc:
      "https://user-images.githubusercontent.com/87645745/213859618-dedb91b2-79ae-4165-8dd9-07fc3e68e726.jpg",
    imageAlt: "Theme2",
  },
  {
    id: "Theme3",
    imageSrc: "https://testanalytics-5e6e3.web.app/static/media/3.776a5714.JPG",
    imageAlt: "Theme3",
  },
  // {
  //   id: "Theme4",
  //   imageSrc:
  //     "https://www.myperfectresume.com/wp-content/uploads/2023/10/Student-Resume-Example.svg",
  //   imageAlt: "Theme4",
  // },
  // {
  //   id: "Theme5",
  //   imageSrc: "https://testanalytics-5e6e3.web.app/static/media/4.af60bc02.jpg",
  //   imageAlt: "Theme5",
  // },
];
  
export default ThemeTemplateData;
