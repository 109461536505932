import React, { useContext, useEffect, useState } from "react";
import "./userCollectData.css";
import { IoMdCloudUpload } from "react-icons/io";
import {
  FormControl,
  Input,
  Heading,
  Textarea,
  Button,
  Switch,
} from "@chakra-ui/react";
import ResumeContext from "../../Context/ResumeContext";

const UserDataCollect = () => {
  const {
    themeData,
    checkAward,
    setCheckAward,
    setThemeData,
    checkProj,
    checkWork,
    setCheckProj,
    setCheckWork,
  } = useContext(ResumeContext);

  const [projectCount, setProjectCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [workCount, setWorkCount] = useState(0);

  const [projectData, setProjectData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [workData, setWorkData] = useState([]);
  const [personalData, setPersonalData] = useState({
    profileImage: "https://www.w3schools.com/howto/img_avatar.png",
    name: "Your Name",
    summary: "Lorem ipsum dolor sit amet, consectetur adipiscing eli",
    profile: "Work Profile",
    address: "Address Line",
    phone: "Phone Number",
    email: "Email Address",
    skill: "Your, Skills, are, shown, here",
  });
  const [awardData, setAwardData] = useState({
    awards: "Your Awards are shown here",
  });

  // To Add Personal Data to the State
  const handleChangePersonal = (e) => {
    const { name, value } = e.target;
    setPersonalData({ ...personalData, [name]: value });
    if (e.target.name === "profileImage") {
      setPersonalData({
        ...personalData,
        profileImage: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  // To Add Project Data to the State
  const handleChangeProject = (e, index) => {
    const { name, value } = e.target;
    const updatedProjects = [...projectData];
    updatedProjects[index] = {
      ...updatedProjects[index],
      [name]: value,
    };
    setProjectData(updatedProjects);
  };

  const handleProjectClick = (e) => {
    e.preventDefault();
    setProjectData([
      ...projectData,
      { pTitle: "Project Title", pDescription: "Project Description" },
    ]);
    setProjectCount(projectCount + 1);
  };

  // To Add Education Data to the State
  const handleChangeEducation = (e, index) => {
    const { name, value } = e.target;
    const updatedEducation = [...educationData];
    updatedEducation[index] = {
      ...updatedEducation[index],
      [name]: value,
    };
    setEducationData(updatedEducation);
  };

  const handleEducationClick = (e) => {
    e.preventDefault();
    setEducationData([
      ...educationData,
      { eTitle: "Education Title", eDescription: "Education Description" },
    ]);
    setEducationCount(educationCount + 1);
  };

  // To Add Work Data to the State
  const handleChangeWork = (e, index) => {
    const { name, value } = e.target;
    const updatedWork = [...workData];
    updatedWork[index] = {
      ...updatedWork[index],
      [name]: value,
    };
    setWorkData(updatedWork);
  };

  const handleWorkClick = (e) => {
    e.preventDefault();
    setWorkData([
      ...workData,
      { wTitle: "Work Title", wDescription: "Work Description" },
    ]);
    setWorkCount(workCount + 1);
  };

  // To Add Award & Achievement Data to the State
  const handleChangeAwards = (e) => {
    const { name, value } = e.target;
    setAwardData({ ...awardData, [name]: value });
  };

  useEffect(() => {
    setThemeData({
      ...themeData,
      personalData,
      projectData,
      educationData,
      workData,
      awardData,
    });
  }, [
    themeData,
    personalData,
    setThemeData,
    projectData,
    educationData,
    workData,
    awardData,
  ]);

  return (
    <>
      <div id="form-collect">
        {/* Personal Details Area */}
        <div id="form-personal" className="mb-2">
          <Heading as="h4" size="md" className="mb-2">
            Personal Details
          </Heading>
          <hr />
          <FormControl isRequired className="my-2">
            <div className="file">
              <label htmlFor="input-file">
                <IoMdCloudUpload size={30} />
                Select a file
              </label>
              <input
                accept="image/*"
                name="profileImage"
                onChange={handleChangePersonal}
                id="input-file"
                type="file"
              />
              <img
                className="blah"
                src={personalData.profileImage}
                alt="your profile preview"
              />
            </div>
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="name"
              onChange={handleChangePersonal}
              type="text"
              placeholder="Your Name"
            />
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="summary"
              onChange={handleChangePersonal}
              type="text"
              placeholder="Your Summary"
            />
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="profile"
              onChange={handleChangePersonal}
              type="text"
              placeholder="Work Profile"
            />
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="address"
              onChange={handleChangePersonal}
              type="text"
              placeholder="Address"
            />
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="phone"
              onChange={handleChangePersonal}
              type="tel"
              placeholder="Phone number"
            />
          </FormControl>
          <FormControl isRequired className="my-2">
            <Input
              name="email"
              onChange={handleChangePersonal}
              type="email"
              placeholder="Email id"
            />
          </FormControl>
        </div>

        {/* Skills Area */}
        <div id="form-personal" className="mb-2">
          <Heading as="h4" size="md" className="my-2">
            Technical Skills
          </Heading>
          <hr />
          <FormControl isRequired className="my-2">
            <Input
              name="skill"
              onChange={handleChangePersonal}
              type="text"
              placeholder="Separate skills by comma"
            />
          </FormControl>
        </div>

        {/* Education Area */}
        <div id="form-personal" className="mb-2">
          <Heading as="h4" size="md" className="my-2">
            Education
          </Heading>
          <hr />
          <Button
            onClick={handleEducationClick}
            className="my-3 w-100"
            colorScheme="teal"
            variant="solid"
          >
            Add Education
          </Button>
          {educationData.map((_, index) => (
            <div key={index}>
              <FormControl isRequired className="my-2">
                <Input
                  id={`eTitle${index}`}
                  name="eTitle"
                  onChange={(e) => handleChangeEducation(e, index)}
                  type="text"
                  placeholder="Enter Title"
                />
              </FormControl>
              <FormControl isRequired className="my-2">
                <Textarea
                  id={`eDescription${index}`}
                  name="eDescription"
                  onChange={(e) => handleChangeEducation(e, index)}
                  placeholder="Use comma to separate Description"
                />
              </FormControl>
            </div>
          ))}
        </div>

        {/* Projects Area */}
        <div id="form-personal" className="mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <Heading as="h4" size="md" className="my-2">
              Projects
            </Heading>
            <Switch
              defaultChecked={true}
              onChange={() => setCheckProj(!checkProj)}
              colorScheme="teal"
            />
          </div>
          <hr />
          <Button
            disabled={checkProj}
            onClick={handleProjectClick}
            className="my-3 w-100"
            colorScheme="teal"
            variant="solid"
          >
            Add Projects
          </Button>
          {projectData.map((_, index) => (
            <div key={index}>
              <FormControl isRequired className="my-2">
                <Input
                  id={`pTitle${index}`}
                  name="pTitle"
                  onChange={(e) => handleChangeProject(e, index)}
                  type="text"
                  placeholder="Enter Project Title"
                />
              </FormControl>
              <FormControl isRequired className="my-2">
                <Textarea
                  id={`pDescription${index}`}
                  name="pDescription"
                  onChange={(e) => handleChangeProject(e, index)}
                  placeholder="Use comma to separate Description"
                />
              </FormControl>
            </div>
          ))}
        </div>

        {/* Work Experience Area */}
        <div id="form-personal" className="mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <Heading as="h4" size="md" className="my-2">
              Work Experience
            </Heading>
            <Switch
              defaultChecked={true}
              onChange={() => setCheckWork(!checkWork)}
              colorScheme="teal"
            />
          </div>
          <hr />
          <Button
            disabled={checkWork}
            onClick={handleWorkClick}
            className="my-3 w-100"
            colorScheme="teal"
            variant="solid"
          >
            Add Experience
          </Button>
          {workData.map((_, index) => (
            <div key={index}>
              <FormControl isRequired className="my-2">
                <Input
                  id={`wTitle${index}`}
                  name="wTitle"
                  onChange={(e) => handleChangeWork(e, index)}
                  type="text"
                  placeholder="Enter Job Title"
                />
              </FormControl>
              <FormControl isRequired className="my-2">
                <Textarea
                  id={`wDescription${index}`}
                  name="wDescription"
                  onChange={(e) => handleChangeWork(e, index)}
                  placeholder="Use comma to separate Description"
                />
              </FormControl>
            </div>
          ))}
        </div>

        {/* Awards & Achievement */}
        <div id="form-personal" className="mb-2">
          <div className="d-flex align-items-center justify-content-between">
            <Heading as="h4" size="md" className="my-2">
              Awards & Achievement
            </Heading>
            <Switch
              defaultChecked={true}
              onChange={() => setCheckAward(!checkAward)}
              colorScheme="teal"
            />
          </div>
          <hr />
          <FormControl isRequired className="my-2">
            <Textarea
              name="awards"
              disabled={checkAward}
              onChange={handleChangeAwards}
              placeholder="Use comma to separate Achievement"
            />
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default UserDataCollect;
