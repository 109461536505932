import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { FaPrint, FaSave } from "react-icons/fa";
import UserDataCollect from "../Components/UserDataCollect/UserDataCollect";
import ResumeContext from "../Context/ResumeContext";

import { toast } from "sonner";
import "./BuilderArea.css";

const BuilderArea = ({ theme, token }) => {
  const {
    setCurrentTheme,
    handlePrint,
    currentTheme,
    themeData,
    setThemeData,
  } = useContext(ResumeContext);
  const [show, setShow] = useState(false);
  const [resumeName, setResumeName] = useState("");
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resumeId, setResumeId] = useState("");
  useEffect(() => {
    const fetchResumes = async () => {
      if (!token) return;
      try {
        setLoading(true);
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/resume/user/all?token=${token}`
        );
        setResumes(res.data.data);
      } catch (e) {
        toast.error(e?.response?.data?.message ?? e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchResumes();
  }, []);
  const handleSave = async () => {

    try {
      if (!resumeName) {
        toast.error("Please enter a resume name");
        return;
      }
      if (!token) {
        toast.error("Resume token is not found");
        return;
      }
      if (!themeData) {
        toast.error("Resume data is not found");
        return;
      }

      const { personalData, projectData, educationData, workData, awardData } =
        themeData;

      // Validate personal data
      if (!personalData.name) {
        toast.error("Please enter your name");
        return;
      }
      if (!personalData.summary) {
        toast.error("Please enter a summary");
        return;
      }
      if (!personalData.profile) {
        toast.error("Please enter your work profile");
        return;
      }
      if (!personalData.address) {
        toast.error("Please enter your address");
        return;
      }
      if (!personalData.phone) {
        toast.error("Please enter your phone number");
        return;
      }
      if (!personalData.email) {
        toast.error("Please enter your email address");
        return;
      }
      if (!personalData.skill) {
        toast.error("Please enter your skills");
        return;
      }

      // Validate project data
      if (projectData.length > 0) {
        for (let i = 0; i < projectData.length; i++) {
          if (!projectData[i].pTitle || !projectData[i].pDescription) {
            toast.error(
              `Please fill in the project title and description for project ${
                i + 1
              }`
            );
            return;
          }
        }
      }

      // Validate education data
      if (educationData.length > 0) {
        for (let i = 0; i < educationData.length; i++) {
          if (!educationData[i].eTitle || !educationData[i].eDescription) {
            toast.error(
              `Please fill in the education title and description for education ${
                i + 1
              }`
            );
            return;
          }
        }
      }

      // Validate work data
      if (workData.length > 0) {
        for (let i = 0; i < workData.length; i++) {
          if (!workData[i].wTitle || !workData[i].wDescription) {
            toast.error(
              `Please fill in the work title and description for work ${i + 1}`
            );
            return;
          }
        }
      }

      // Validate award data
      if (awardData.awards.length === 0) {
        toast.error("Please enter your awards");
        return;
      }

      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/resume`, {
        resumeName,
        token,
        ...themeData,
      });

      // If all checks pass, proceed with saving
      setShow(false);
      toast.success("Resume saved successfully");
    } catch (e) {
      toast.error(e?.response?.data?.message ?? e.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (resumeId) {
      const findResume = resumes.find((resume) => resume._id === resumeId);

      if (findResume) {
        setThemeData(findResume);
      }
    }
  }, [resumeId]);
  if (loading) {
    return <span className="loading">Loading...</span>;
  }

  return (
    <>
      <Modal isOpen={show} onClose={setShow}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save Resume</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter resume name"
              value={resumeName}
              onChange={(e) => setResumeName(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignItems: "center",
          gap: "20px",
          margin: "20px 0",
        }}
      >
        <Button
          style={{ margin: "0 10px" }}
          colorScheme={"teal"}
          variant={"outline"}
          onClick={handlePrint}
        >
          <FaPrint size={20} />
        </Button>
        <Button
          style={{ margin: "0 10px" }}
          colorScheme={"teal"}
          variant={"outline"}
          onClick={() => setShow(true)}
        >
          <FaSave size={20} />
        </Button>
        <Select
          id="theme-select"
          colorScheme="teal"
          variant={"outline"}
          onChange={(e) => setCurrentTheme(e.target.value)}
          value={currentTheme}
          style={{
            display: "flex",
            maxWidth: "200px",
            margin: "0 10px",
            minWidth: "150px",
            padding: "10px 15px",
            borderRadius: "5px",

            outline: "none",
            cursor: "pointer",
          }}
        >
          <option value="" disabled>
            Select a theme
          </option>
          <option className=" " value="Theme1">
            Theme 1
          </option>
          <option className=" " value="Theme2">
            Theme 2
          </option>
          <option className=" " value="Theme3">
            Theme 3
          </option>
        </Select>
        <Select
          id="theme-select"
          colorScheme="teal"
          variant={"outline"}
          onChange={(e) => setResumeId(e.target.value)}
          value={resumeId}
          style={{
            display: "flex",
            maxWidth: "200px",
            margin: "0 10px",
            minWidth: "150px",
            padding: "10px 15px",
            borderRadius: "5px",
            outline: "none",
            cursor: "pointer",
          }}
        >
          {resumes.map((resume, index) => (
            <option key={index} className=" " value={resume._id}>
              {resume.resumeName}
            </option>
          ))}
        </Select>
      </div>

      <div id="main-box" className="d-flex justify-content-between  mt-4 mx-2">
        <UserDataCollect />
        <div id="theme-box-border">{theme}</div>
      </div>
    </>
  );
};

export default BuilderArea;
