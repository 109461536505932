import React from "react";
import ResumeState from "./Context/ResumeState";
import Home from "./Pages/Home/Home";
import { Toaster } from "sonner";

function App() {
  return (
    <ResumeState>
      <Toaster richColors position="top-center" />
      <Home />
    </ResumeState>
  );
}

export default App;
