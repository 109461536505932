import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import ResumeContext from "../../Context/ResumeContext";
import ThemeTemplateData from "../../db/ThemeTemplateData";
import "./introduction.css";

export default function Introduction({ setToken }) {
  const { setCurrentTheme, showComponent, setShowComponent } =
    useContext(ResumeContext);

  const showTheme = (e) => {
    setShowComponent(!showComponent);
    setCurrentTheme(e.target.id);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const theme = urlParams.get("theme");
  const token = urlParams.get("token");
  const { setColorMode } = useColorMode();
  const bgColor = useColorModeValue("white", "#060620");

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, [bgColor]);

  useEffect(() => {
    if (theme) {
      setColorMode(theme);
    }
    if (token) {
      setToken(token);
    }
  }, [theme, token]);
  if (!token) {
    return <span className="loading">Loading...</span>;
  }
  return (
    <>
      <Container
        my={{ base: 1.5, md: 16 }}
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row", sm: "column" }}
        display={"flex"}
        alignItems={"center"}
        maxW={"7xl"}
        bg={bgColor}
      >
        <Stack
          width={{ base: "95%", md: "47%" }}
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 1.5, md: 10, sm: "14" }}
        >
          <Heading
            m={"1.5"}
            textAlign={{ base: "center", md: "start" }}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Select a{" "}
            <Text as={"span"} color={"#38B2AC"}>
              Template{" "}
            </Text>
            from the list
          </Heading>
        </Stack>

        <>
          <Box maxW={{ base: "100%", md: "61%" }} className="templatesList">
            {ThemeTemplateData.map((item, index) => {
              return (
                <div key={index} className="template" onClick={showTheme}>
                  <img id={item.id} src={item.imageSrc} alt={item.imageAlt} />
                </div>
              );
            })}
          </Box>
        </>
      </Container>
    </>
  );
}
